<script setup>

/**
 *  Imports
 */
import { useGlobalStore } from '@/store/global'
import { useMessageStore } from '@/store/message'

/**
 *  Composables
 */
const store = useGlobalStore()
const message = useMessageStore()

const datas = reactive({
    error: false,
    errors: false
});

const email = ref("");
const content = ref("");

function handleErrorMessage(translation, reference, emailValue, messageValue){

    // validateInputs();

    const { data, pending, refresh, execute, error } = useFetch("/api/verseerror", {
        method: "POST",
        body: {
            translation: translation,
            reference: reference,
            email: emailValue,
            message: messageValue,
        },
        onRequest({ request, options }) {
            // Set the request headers
        },
        onRequestError({ request, options, error }) {
            // Handle the request errors
        },
        onResponse({ request, response, options }) {

            if(response._data.errors){

                datas.errors = response._data.errors

            }else {

                message.createMessage("success", "Správa bola úspešne odoslaná. Ďakujeme.");
                content.value = "";
                datas.error = false
                datas.errors = false
                store.handleErrorVerse(false)
                toggleSidebar('error')
                return true;

            }

        },
        onResponseError({ request, response, options }) {
        }
    })

}

function validateInputs() 
{

    if(content.value.length < 3 || !Boolean(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value))){
        datas.error = true
    }else {
        datas.error = false
    }

}

</script>
<template>
    <Transition name="fade">
        <div v-if="store.getSidebarType == 'error'" @click="toggleSidebar('error'); datas.error = false; datas.errors = false" :class="['bg-gray-100 z-50 opacity-80 fixed left-0 right-0 top-0 bottom-0 mx-auto']"></div>
    </Transition>

    <Transition name="slide-fade">
    <div v-if="store.getSidebarType == 'error'" class="fixed bg-white top-0 bottom-0 right-0 w-full md:w-96 overflow-auto text-center border-l z-50">

        <h2 class="my-4 uppercase text-xs text-gray-700 relative">
            <svg @click="toggleSidebar('error')" class="absolute lg:hidden cursor-pointer left-2 text-gray-400 top-0 bottom-0 my-auto w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
            Nahlásenie chyby verša
        </h2>

        <div class="text-gray-500 font-bold leading-5">
            {{ store.getSelectedTranslation.name }}<br>
            {{ store.getSelectedBook.name }}
            {{ store.getSelectedChapter }},{{ store.getErrorVerse }}
        </div>

        <div class="px-4 mt-4">
            <input class="bg-input-bg border p-2 my-2 w-full box-border" v-model="email" type="email" placeholder="Váš e-mail">
            <textarea v-model="content" class="bg-input-bg border p-2 my-2 w-full box-border" name="error_description" cols="30" rows="4" placeholder="Popis chyby"></textarea>
            <button @click="handleErrorMessage(store.getSelectedTranslation.name, store.getSelectedBook.name + ' ' + store.getSelectedChapter + ',' + store.getErrorVerse, email, content);" class="bg-blue-500 hover:bg-blue-600 hover:shadow cursor-pointer text-primary-fg p-2 rounded block w-full">Nahlásiť chybu</button>
        </div>

        <ul v-if="datas.errors && !datas.success" class="text-red-500 text-center font-bold px-4">
            <li class="bg-red-50 my-2 p-2 rounded border border-red-200" v-for="error, index in datas.errors" :key="index">
                {{ error[0] }}
            </li>
        </ul>

        <hr class="mt-4">

        <button @click="toggleSidebar('menu'); datas.error = false; datas.errors = false" class="my-6 text-gray-500 hover:text-black">Zatvoriť</button>

    </div>
    </Transition>
</template>